import {IConfig} from './Config';

export const config: IConfig = {
  firebaseConfig: {
    apiKey: 'AIzaSyB_flMz1zoJqq3E4_tMdZvYnk0BkXQYlmk',
    authDomain: 'rts-admin.chancerhq.com',
    projectId: 'chancerhq-staging',
    storageBucket: 'chancerhq-staging.appspot.com',
    messagingSenderId: '374182407761',
    appId: '1:374182407761:web:c66ea8f1a6d3e173f7d270',
    measurementId: 'G-NNWZ9BK0FX',
  },
  webDomain: 'rts-www.chancerhq.com',
  appDomain: 'rts-app.chancerhq.com',
  adminDomain: 'rts-admin.chancerhq.com',
  cdnDomain: 'rts-cdn.chancerhq.com',
  environment: 'staging',
};
