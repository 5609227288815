import React, {useState} from 'react';

import axios from 'axios';

import log from '@chancer/common/lib/utils/Log';

import {config} from './config/config-chancer-development';

import {GoogleAuthProvider, onAuthStateChanged} from 'firebase/auth';
import './App.scss';
import StyledFirebaseAuth from './components/components/mui/Auth/StyledFirebaseAuth';
import {getFirebaseAuth} from './Firebase';

onAuthStateChanged(getFirebaseAuth(), async (user) => {
  console.log('Auth state changed', user);
  if (user !== null) {
    const token = await user.getIdToken();
    try {
      await axios.get(
        `${config.environment === 'local' ? 'http' : 'https'}://${
          config.adminDomain
        }/getSession?idToken=${token}`,
      );
      window.location.replace('/');
    } catch (err) {
      console.log(err);
      getFirebaseAuth().signOut();
      window.location.reload();
    }
  }
});

const App: React.FC = () => {
  const [pendingRedirect, setPendingRedirect] = useState(false);
  const uiCallback = (ui: firebaseui.auth.AuthUI) => {
    console.log('UI created');
    console.log('isPending', ui.isPendingRedirect());
    setPendingRedirect(ui.isPendingRedirect());
  };

  return (
    <div className="login__container">
      <div className="login__logo" />
      {pendingRedirect ? (
        <div className="pt-2">Signing you in</div>
      ) : (
        <div className="pt-2">Please sign in</div>
      )}
      <StyledFirebaseAuth
        className="auth__firebase-ui"
        uiCallback={uiCallback}
        uiConfig={{
          signInFlow: 'popup',
          //signInSuccessUrl: `${window.location.protocol}//${window.location.hostname}/signup${window.location.search}`,
          callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
              log.info('Sign in success called', authResult, redirectUrl);
              return false;
            },
            signInFailure: (error) => {
              log.info('Sign in error', error);
              return new Promise((resolve, reject) => {
                log.info('Sign in error', error);
                resolve();
              });
            },
            uiShown: () => {
              log.info('UI shown');
            },
          },
          signInOptions: [GoogleAuthProvider.PROVIDER_ID],
        }}
        firebaseAuth={getFirebaseAuth()}
      />
      <div className="login__spacer" />
    </div>
  );
};

export default App;
